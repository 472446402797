<script setup>
import {useStore} from "vuex";
import {computed, onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import {loginByCode, loginByUserName, sendBindCode, sendLoginCode, updatePassword} from "@/services/api.js";
import {showToast} from "vant";
import Cookies from "js-cookie";

const store = useStore()
const router = useRouter()

const state = reactive({
  telephone: "",
  code: "",
  isUserNameLogin: true,
  username: "",
  password: "",
  codeMsg: "发送验证码",
  loginText: "手机号登录",
  time: 0
})

const onTextClick = () => {
  state.isUserNameLogin = !state.isUserNameLogin
  if (state.isUserNameLogin) {
    state.loginText = "手机号登录"
  } else {
    state.loginText = "密码登录"
  }
}

const sendCode = () => {
  console.log("231232312")
  if (!state.telephone) {
    showToast("请填写手机号码")
    return
  }
  if (state.time > 1) {
    return
  }
  state.time = 60;
  sendLoginCode({
    token: Cookies.get("token"),
    telephone: state.telephone
  }).then(res => {
    if (res.status === 1) {
      let timer = setInterval(() => {
        state.time--;
        if (state.time <= 0) {
          state.time = 0;
          clearInterval(timer);
        }
      }, 1000)
    } else {
      state.time = 0;
    }
  }).catch(res => {
    state.time = 0;
    showToast(res.message)
  });
}


const submit = () => {
  if (state.isUserNameLogin) {
    if (!state.username) {
      showToast("请输入账号")
      return
    }
    if (!state.password) {
      showToast("请输入密码")
      return
    }
    loginByUserName({
      token: Cookies.get("token"),
      password: state.password,
      username: state.username,
    }).then(res => {
      if (res.status === 1) {
        showToast("账号成功登录")
        localStorage.setItem("token", res.data.token)
        Cookies.set("token", res.data.token)
        store.dispatch("getUser")
        setTimeout(() => {
          router.back()
        }, 1500)
      }
    })
  } else {
    if (!state.telephone) {
      showToast("请输入手机号")
      return
    }
    if (!state.code) {
      showToast("请输入验证码")
      return
    }
    loginByCode({
      token: Cookies.get("token"),
      telephone: state.telephone,
      code: state.code,
    }).then(res => {
      if (res.status === 1) {
        showToast("账号成功登录")
        localStorage.setItem("token", res.data.token)
        Cookies.set("token", res.data.token)
        store.dispatch("getUser")
        setTimeout(() => {
          router.back()
        }, 1500)
      }
    })
  }
}
onMounted(() => {
})
</script>

<template>
  <div class="wrapper">
    <div class="header">
      <img class="back" src="@/assets/img/back.png" @click="router.back()"/>
      <div class="title">切换账号</div>
    </div>
    <div class="input-item" v-if="state.isUserNameLogin">
      <img class="my-icon" src="@/assets/img/icon_user.png"/>
      <input class="input" type="text" v-model="state.username" placeholder="请输入帐号" maxlength="10"/>
    </div>
    <div class="input-item" v-if="state.isUserNameLogin">
      <img class="my-icon" src="@/assets/img/icon_password.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.password ? state.password : '请输入密码'"
             v-model="state.password"/>
    </div>
    <div class="input-item" v-if="!state.isUserNameLogin">
      <img class="my-icon" src="@/assets/img/icon_phone.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.telephone ? state.telephone : '请输入手机号'"
             v-model="state.telephone"/>
    </div>
    <div class="input-item" v-if="!state.isUserNameLogin">
      <img class="my-icon" src="@/assets/img/icon_password.png"/>
      <input class="input" type="text" placeholder="请输入验证码" maxlength="6" v-model="state.code"/>
      <div :type="2" class="send-txt" @click="sendCode()">
        {{ state.time === 0 ? state.codeMsg : state.time + ' s' }}
      </div>
    </div>
    <div class="loginText" @click="onTextClick">{{ state.loginText }}</div>
    <div class="submit" @click="submit">
      <div class="n-btn ">确认</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  min-width: 375px;
  align-items: center;

  .header {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    .back {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      flex: 1;
    }
  }

  .loginText {
    color: white;
    font-size: 13px;
    cursor: pointer;
    margin-top: 10px;
  }


  .submit {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/recharge_btn_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 30px;
    cursor: pointer;
  }


  .input-item {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/repo_tab_header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    color: white;


    .send-txt {
      min-width: 80px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      font-size: 13px;
      color: white;
      cursor: pointer;
    }


    .my-icon {
      width: 15px;
      height: 15px;
      margin-left: 12px;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      color: white;
      width: 100px;
    }

    .input {
      flex: 1;
      height: 30px;
      border: none;
      background: transparent;
      text-align: start;
      color: white;
      font-size: 14px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

}
</style>