import axios from 'axios';
import Cookies from "js-cookie";
import {showFailToast} from 'vant';
import store from "@/store";
import router from "@/router";


// 创建一个 Axios 实例
const instance = axios.create({
    timeout: 6000, // 设置超时时间
    headers: {
        'Content-Type': 'application/json', 'token': Cookies.get('token') || ''
    } // 设置默认请求头
});
// 添加请求拦截器
instance.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    return config;
}, error => {
    // 处理请求错误
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
    // 对响应数据做些什么
    return response;
}, error => {
    // 处理响应错误
    return Promise.reject(error);
});

function checkStatus(response) {
    // loading
    // 如果http状态码正常，则直接返回数据
    // Toast.clear('all')
    if (response && (response?.status === 200 || response?.status === 304 || response?.status === 400)) {
        return response.data
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }
    if (response?.data?.status === 403) {
        Cookies.set('token', '', {expires: -1})
        store.state.showLogin = true
        router.push('/').then(r => {
        })
        return
    }
    // 异常状态下，把错误信息返回去
    return {
        status: -404, msg: '网络异常'
    }
}

function checkCode(res) {
    // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
    if (res.status === -404) {
        return showFailToast(res.message)
    }
    if (res.status !== 1 && (res.message !== 'success')) {
        // alert(res.data.error_msg)
        showFailToast(res.message)
    }
    return res
}


export const POST = function (url, data) {
    // let enData = Encrypt(JSON.stringify(data))
    return axios({
        method: 'POST', url: url, data: data,
    }).then((response) => {
        return checkStatus(response)
    }).then((res) => {
        return checkCode(res)
    })
}


export default instance;
