<script setup>
import {useStore} from "vuex";
import {computed} from "vue";
import {useRouter} from "vue-router";
import {showToast} from "vant";
import useClipboard from 'vue-clipboard3';
import {showSuccessToast} from "vant/lib/toast/function-call.js";

const store = useStore()
const router = useRouter()
const {toClipboard} = useClipboard();
const userInfo = computed(() => store.state.userInfo)
const toOther = (path) => {
  if (path === "/bind" && userInfo.value.telephone) {
    showToast("手机号已绑定")
    return
  }
  router.push(path)
}
const share = () => {
  try {
    toClipboard(store.state.config.share_url);
    showSuccessToast('分享链接已复制');
  } catch (e) {
    console.error(e);
  }
}

const toService = () => {
  window.location.href = store.state.config.service_url
}
const onSwClick = () => {
  try {
    toClipboard(store.state.config.telegram_username);
    showSuccessToast('联系TG号已复制');
  } catch (e) {
    console.error(e);
  }
}

</script>

<template>
  <div class="wrapper">
    <div class="header_box">
      <div class="header">
        <img class="img" src="@/assets/img/header_def.png"/>
        <!--        <div class="change">点击替换</div>-->
      </div>
      <div class="title_box">
        <div class="title">{{ userInfo.username }}&nbsp;&nbsp;&nbsp;密码:&nbsp;{{ userInfo.password }}</div>
        <div class="second_title">欢迎加入，更多精彩等你发现</div>
      </div>
    </div>
    <div class="history_box">
      <div class="box1" @click="toOther('/repoLog')">
        <img class="icon" src="@/assets/img/mine_cjjl.png"/>
        <div class="title">抽奖记录</div>
      </div>
      <div class="box2" @click="toOther('/rechargeLog')">
        <img class="icon" src="@/assets/img/mine_czjl.png"/>
        <div class="title">充值记录</div>
      </div>
    </div>
    <div class="function_box">
      <div class="box1" @click="toOther('/recycle')">
        <img class="icon" src="@/assets/img/mine_yjhs.png"/>
        <div class="title">一键回收</div>
      </div>
      <div class="box1" @click="share">
        <img class="icon" src="@/assets/img/mine_fxyq.png"/>
        <div class="title">分享邀请</div>
      </div>
      <div class="box1" @click="onSwClick">
        <img class="icon" src="@/assets/img/mine_swhz.png"/>
        <div class="title">商务合作</div>
      </div>
      <div class="box1" @click="toService">
        <img class="icon" src="@/assets/img/mine_sxkf.png"/>
        <div class="title">私信客服</div>
      </div>
    </div>

    <div class="bottom_box">
      <div class="box1" @click="toOther('/update')">
        <img class="icon" src="@/assets/img/icon_password.png"/>
        <div class="title">修改密码</div>
        <img class="arrow" src="@/assets/img/arrow_right.png"/>
      </div>
      <div class="line"></div>
      <div class="box1" @click="toOther('/bind')">
        <img class="icon" src="@/assets/img/icon_phone.png"/>
        <div class="title">绑定手机</div>
        <img class="arrow" src="@/assets/img/arrow_right.png"/>
      </div>
      <div class="line"></div>
      <div class="box1" @click="toOther('/login')">
        <img class="icon" src="@/assets/img/icon_user.png"/>
        <div class="title">切换账号</div>
        <img class="arrow" src="@/assets/img/arrow_right.png"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 30px 12px 0px 12px;
  min-width: 375px;
  min-height: 100%;
  height: unset;


  .header_box {
    display: flex;
    align-items: center;
    margin-left: 12px;


    .title_box {
      display: flex;
      flex-direction: column;
      width: calc(100% - 58px);
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 12px;


      .title {
        color: white;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        flex: 1;
      }

      .second_title {
        color: white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }


    .header {
      width: 58px;
      height: 58px;
      flex-shrink: 0;
      position: relative;
      display: flex;
      justify-content: center;
      margin-left: 12px;
      cursor: pointer;

      .img {
        width: 58px;
        height: 58px;
        flex-shrink: 0;
      }

      .change {
        width: 46px;
        height: 14px;
        flex-shrink: 0;
        border-radius: 13px;
        border: 0.5px solid #E5EBFF;
        background: linear-gradient(180deg, rgba(74, 98, 203, 0.50) 0%, #4A62CB 110.06%);
        backdrop-filter: blur(6px);
        position: absolute;
        color: #FFF;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        bottom: 0;
      }
    }

  }

  .history_box {
    display: flex;
    flex-direction: row;
    margin: 30px 0px 0px 0px;


    .box1, .box2 {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid #7E7BFF;
      background: radial-gradient(65.45% 52.32% at 50% 113.62%, rgba(126, 124, 255, 0.60) 0%, rgba(150, 124, 255, 0.00) 100%), linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 91.33%), rgba(80, 51, 255, 0.10);
      height: 97px;
      cursor: pointer;

      .icon {
        width: 40px;
        height: 40px;
      }

      .title {
        color: white;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        margin-top: 6px;
        font-weight: 500;
        letter-spacing: 0.2px;

      }
    }

    .box1 {
      margin-right: 18px;
    }
  }

  .function_box {
    display: flex;
    flex-direction: row;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 16px;
    background-image: url("@/assets/img/function_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;


    .box1 {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 57px;
      gap: 6px;
      cursor: pointer;

      .icon {
        width: 40px;
        height: 40px;
      }

      .title {
        color: white;
        text-align: center;
        font-style: normal;
        letter-spacing: 0.2px;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
        text-transform: uppercase;
      }
    }
  }

  .bottom_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 16px;
    background-image: url("@/assets/img/function_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 169px;
    padding: 0px 12px 0px 12px;


    .line {
      opacity: 0.1;
      background: #F4F7FF;
      margin-left: 34px;
      width: 96%;
      height: 1px !important;
      display: block;
    }

    .box1 {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;

      .icon {
        width: 18px;
        height: 18px;
      }

      .title {
        color: white;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
        flex: 1;
        text-align: start;
        margin-left: 12px;
      }

      .arrow {
        width: 15px;
        height: 15px;
        flex-shrink: 0;
      }
    }
  }
}
</style>