import {createStore} from 'vuex'
import {getConfig, getUserInfo, loginByUserName, register} from "@/services/api.js"
import Cookies from "js-cookie";

const store = createStore({
    state: {
        count: 0,
        showLoading: false,
        userInfo: {},
        config: {}
    }, mutations: {
        increment(state) {
            state.count++
        },
        setUser(state, userInfo) {
            state.userInfo = userInfo
        },
        setConfig(state, config) {
            state.config = config
        }
    }, actions: {
        increment({commit}) {
            commit('increment')
        },
        getUser({commit}, params) {
            if (!Cookies.get("token")) {
                register({domain: window.location.origin, t: params || null}).then(res => {
                    Cookies.set("token", res.data.token)
                    localStorage.setItem("userInfo", JSON.stringify(res.data))
                    commit("setUser", res.data)
                })
            } else {
                getUserInfo({token: Cookies.get("token")}).then(res => {
                    localStorage.setItem("userInfo", JSON.stringify(res.data))
                    commit("setUser", res.data)
                })
            }

        },
        register({commit}, params) {
        },
        getConfig({commit}) {
            getConfig({}).then(res => {
                localStorage.setItem("config", JSON.stringify(res.data.config))
                commit("setConfig", res.data.config)
            })
        },
        loginByUserName({commit}, params) {
            loginByUserName({username: params?.username, password: params?.password}).then(res => {
                localStorage.setItem("config", JSON.stringify(res.data.config))
                Cookies.set("token", res.data.token)
                commit("setUser", res.data)
            })
        }
    }, getters: {
        doubleCount: (state) => state.count * 2
    }
})

export default store
