<script setup>
import {onMounted, reactive, ref} from "vue";
import {getCJList} from "@/services/api.js";
import Cookies from "js-cookie";
import {useRouter} from "vue-router";

const domList = ref(null)
const router = useRouter()

const state = reactive({
  tabList: [
    {
      label: "抽奖记录",
      type: 1
    },
  ],
  headerList: [
    {
      label: "消费金币",
      type: 1
    }, {
      label: "抽奖次数",
      type: 2
    }, {
      label: "抽奖时间",
      type: 3
    },
  ],
  list: [],
  tabIndex: 0,
  page: 0,
  itemIndex: 0,
  loading: false,
  finished: false,
  empty: false,
  showPopu: false,
  action: "",
  id: "",
  currentItem: {}
})

const onTabClick = (index) => {
  router.back(-1)
}

const getRepoList = () => {
  state.page++
  getCJList({token: Cookies.get("token"), page: state.page}).then(res => {
    state.loading = false
    if (res.data.pagination.max_page === res.data.pagination.page) {
      state.finished = true
    }
    if (res.data.lottery_list == null || res.data.lottery_list.length === 0) {
      state.empty = true
    } else {
      state.list = state.list.concat(res.data.lottery_list)
      state.empty = false
    }
  })
}

const onLoad = () => {
  state.loading = true
  console.log("加载嗯多")
  getRepoList()
}

onMounted(() => {
  console.log(domList.value)
  domList.value.addEventListener('scroll', (event) => {
    console.log(21212)
    const {scrollTop, clientHeight, scrollHeight} = event.target
    if (scrollTop + clientHeight > scrollHeight - 100 && !state.loading && !state.finished) {
      onLoad()
    }
  })
  onLoad()
})

</script>

<template>
  <div class="wrapper">
    <div class="tab_box">
      <div class="tab" v-for="(item,index) in state.tabList" :class="state.tabIndex===index?'tab_active':''"
           @click="onTabClick(index)">
        <img class="icon" src="@/assets/img/arrow_right.png"/>
        <span> {{ item.label }}</span>
      </div>
    </div>
    <div class="table_header" v-if="!state.empty">
      <div class="tab" v-for="(item,index) in state.headerList">{{ item.label }}
      </div>
    </div>
    <div class="list_box" ref="domList">
      <div v-if="!state.empty" class="item_box" v-for="(item,index) in state.list" ref="domList">
        <div class="img_bg">
          <!--          <img class="img" :src="item.image_url"/>-->
          <div class="name">{{ item.coin }}金币</div>
        </div>
        <div class="info_box">
          <div class="info">
            <div class="name">{{ item.lottery_count }}</div>
            <div class="date">{{ item.created_at }}</div>
          </div>
          <!--          <div class="line"></div>-->
          <!--          <div class="btn_box">-->
          <!--          </div>-->
        </div>
      </div>
      <div v-else class="empty">
        <img class="icon" src="../../assets/img/list_empty.png"/>
        <div class="title">暂无记录</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@media (min-width: 1024px) {
  .wrapper {
    max-width: 490px;

    .top_tab,.list_box {
      max-width: 490px;
    }
  }
}

.wrapper {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 20px;
  //padding-bottom: 20px;
  //color: white;


  .van-popup {
    background: transparent !important;
  }

  .popu {
    width: 320px;
    min-height: 200px;
    border-radius: 12px;
    background: #282643;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-top: 20px;
    }

    .btn {
      width: 80%;
      height: 40px;
      line-height: 40px;
      background-image: url("@/assets/img/recharge_btn_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 20px;
      margin-bottom: 20px;
      color: white;
      font-size: 16px;
    }

    .item {
      width: 80%;
      height: 36px;
      background-image: url("@/assets/img/repo_tab_header.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;


      .label {
        font-size: 14px;
        font-weight: bold;
        color: white;
        width: 100px;
      }

      .input {
        flex: 1;
        height: 30px;
        border: none;
        background: transparent;
        text-align: start;
        color: white;
        font-size: 13px;
      }
    }
  }


  .tab_box {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 18px 0px 18px;
    justify-content: flex-start;

    .tab {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background-image: url("@/assets/img/tab_normal.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #C8ADFF;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 15px;
        height: 15px;
        object-fit: fill;
        transform: rotate(180deg);
      }
    }

    .tab_active {
      background-image: url("@/assets/img/tab_selected.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #FFF;
      text-shadow: 0px 0px 8px #8C8AFF;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
    }
  }

  .table_header {
    display: flex;
    flex-direction: row;
    margin: 17px 16px 0px 16px;
    justify-content: space-around;
    background-image: url("@/assets/img/repo_tab_header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 46px;

    .tab {
      width: 100px;
      height: 46px;
      line-height: 46px;
      color: #FFF;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }


  .list_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 120px);
    overflow-y: auto;
    margin-top: 6px;

    .empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30%;

      .icon {
        width: 276px;
        object-fit: cover;
      }

      .title {
        color: #FFF;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
      }
    }

    .item_box {
      display: flex;
      flex-direction: row;
      margin: 6px 16px 0px 16px;
      justify-content: space-around;
      background-image: url("@/assets/img/repo_item_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      align-items: center;
      flex: 1;
      min-height: 126px;


      .img_bg {
        background-image: url("@/assets/img/repo_img_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 94px;
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        .name {
          color: white;
        }

        .img {
          width: 52px;
          height: 52px;
          border-radius: 6px;

        }
      }


      .info_box {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 94px;
        margin-left: 20px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;


        .line {
          height: 1px !important;
          opacity: 0.1;
          margin-top: 5px;
          background: #F4F7FF;
        }

        .btn_box {
          width: 100%;
          height: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;
          justify-content: space-between;

          .date {
            color: white;
            text-align: end;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            padding-right: 28px;
            margin-left: 12px;
          }
        }

        .info {
          height: 30px;
          width: 100%;
          display: flex;
          flex-direction: row;

          .name {
            width: 40%;
            line-height: 30px;
            padding-left: 13px;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
          }

          .date {
            width: 60%;
            color: white;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: end;
          }


        }
      }
    }

  }


}
</style>