<script setup>
import {computed, onMounted, reactive} from "vue";
import {useStore} from "vuex";
import {getChargeList, submitCharge} from "@/services/api.js";
import Cookies from "js-cookie";

const store = useStore()
const state = reactive({
  userInfo: {},
  list: [],
  rechargeTypeList: [],
  tabIndex: 0,
  typeIndex: 0,
})

state.userInfo = computed(() => {
  return store.state.userInfo
})

const onTabClick = (index) => {
  state.tabIndex = index
}
const onTypeClick = (index) => {
  state.typeIndex = index
}

const onRechargeClick = () => {
  const params = {
    token: Cookies.get("token"),
    item_id: state.list[state.tabIndex]?.id,
    channel_id: state.rechargeTypeList[state.typeIndex]?.id,
    return_url: window.location.href
  }
  submitCharge(params).then(res => {
    if (res.data.url) {
      setTimeout(()=>{
        window.open(res.data.url, "_blank")
      },100)
    }
  })
}
const getList = () => {
  getChargeList({}).then(res => {
    state.rechargeTypeList = res.data?.channel_list || []
    state.list = res.data?.item_list || []
  })
}

onMounted(() => {
  getList()
})

</script>

<template>
  <div class="wrapper">
    <div class="count_box">
      <div class="title">剩余抽奖次数</div>
      <div class="number_box">
        <div class="number">{{ state.userInfo.lottery_amount }}</div>
        <div class="dw">次</div>
      </div>
    </div>
    <div class="botton_bg">
      <div class="title">选择充值金额</div>
      <div class="channel_box">
        <div class="channel" v-for="(item,index) in state.list" :key="index"
             @click="onTabClick(index)"
             :class="state.tabIndex===index?'channel_active':''">
          <div class="money">{{ item.price_yuan }}元</div>
          <div class="title">{{ item.title }}</div>
        </div>
      </div>
      <div class="title">选择支付方式</div>
      <div class="type_box">
        <div class="type" v-for="(item,x) in state.rechargeTypeList" :key="x" @click="onTypeClick(x)">
          <img class="icon" src="../../assets/img/zfb.png" v-if="item.icon==='zfb'"/>
          <img class="icon" src="../../assets/img/wx.png" v-else/>
          <div class="title">{{ item.name }}</div>
          <div class="radio" :class="state.typeIndex===x?'radio_active':''"></div>
        </div>
      </div>
      <div class="btn" @click="onRechargeClick">立即充值</div>

    </div>
  </div>
</template>

<style lang="scss" scoped>


@media (min-width: 1024px) {
  .wrapper {
    max-width: 490px;
  }
}


.wrapper {
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  min-width: 375px;
  position: fixed;
  min-height: 100%;
  height: unset;

  .count_box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-top: 50px;

    .title {
      color: rgba(255, 255, 255, 0.60);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .number_box {
      color: #FFF;
      font-size: 40px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      align-items: center;

      .number {
        color: #FFF;
        font-size: 40px;
      }

      .dw {
        width: 20px;
        margin-left: 4px;
        color: #FFF;
        font-size: 16px;
        margin-top: 18px;
      }
    }
  }

  .botton_bg {
    width: 100%;
    margin-top: 20px;
    background-image: url("@/assets/img/recharge_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 4px 27px 0px 27px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    .title {
      margin-top: 28px;
      color: #FFF;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      font-family: "PingFang SC";
      line-height: normal;
    }

    .channel_box {
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      min-height: 108px;
      text-align: center;

      .channel {
        background-image: url("@/assets/img/recharge_tab_normal.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 30%;
        height: 62px;
        display: inline-flex;
        flex-direction: column;
        //align-items: center;
        //justify-content: center;


        .money {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.2px;
          margin-top: 12px;
          height: 22px;
          line-height: 22px;
        }

        .title {
          color: #DCCCFF;
          font-family: "PingFang SC";
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.2px;
          margin-top: 6px;
          height: 17px;
          line-height: 17px;
        }
      }

      .channel_active {
        background-image: url("@/assets/img/recharge_tab_selected.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }


    .type_box {
      min-height: 54px;
      width: 100%;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid #7E7BFF;
      background: radial-gradient(65.45% 52.32% at 50% 113.62%, rgba(126, 124, 255, 0.40) 0%, rgba(150, 124, 255, 0.00) 100%), linear-gradient(90deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.00) 91.33%), rgba(80, 51, 255, 0.10);
      background-blend-mode: normal, plus-lighter, normal;

      .type {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 54px;
        align-items: center;
        padding: 0px 11px 0px 11px;
        cursor: pointer;

        .icon {
          width: 26px;
          height: 26px;
        }

        .title {
          font-size: 13px;
          margin-left: 12px;
          flex: 1;
          text-align: start;
          height: 26px;
          line-height: 1px;
        }

        .radio {
          width: 19px;
          height: 19px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("@/assets/img/recharge_radio_normal.png");
        }

        .radio_active {
          width: 19px;
          height: 19px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-image: url("@/assets/img/recharge_radio_selected.png");
        }
      }
    }

    .btn {
      width: 100%;
      height: 44px;
      line-height: 44px;
      background-image: url("@/assets/img/recharge_btn_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 24px;
      color: var(--Others-White, #FFF);
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>