import {POST} from "./services.js"

//用户-注册
export function register(data) {
    return POST('/api/user/register', data)
}

//用户-获取用户信息
export function getUserInfo(data) {
    return POST('/api/user/info', data)
}

//获取网站配置
export function getConfig(data) {
    return POST('/api/config', data)
}

//首页-奖品展示和中奖信息
export function getHome(data) {
    return POST('/api/home', data)
}

//首页-抽奖
export function lottery(data) {
    return POST('/api/lottery', data)
}

//我的-中奖物品
export function getList(data) {
    return POST('/api/my/item', data)
}

//兑换码-兑换物品
export function exchangeCode(data) {
    return POST('/api/my/code-exchange', data)
}
//我的-抽奖记录
export function getCJList(data) {
    return POST('/api/my/lottery-log', data)
}

//充值-物品列表
export function getChargeList(data) {
    return POST('/api/charge/item', data)
}

//充值
export function submitCharge(data) {
    return POST('/api/charge/submit', data)
}


//充值-充值记录
export function getChargeLog(data) {
    return POST('/api/my/charge-log', data)
}

//我的-获取可回收奖品列表
export function getRecycleList(data) {
    return POST('/api/my/get-recycle-item', data)
}

//用户-登录
export function loginByUserName(data) {
    return POST('/api/user/login', data)
}


//用户-绑定手机号-发送验证码
export function sendBindCode(data) {
    return POST('/api/user/bind-send-code', data)
}

//用户-绑定手机号
export function bindPhone(data) {
    return POST('/api/user/bind-phone', data)
}

//用户-手机登录-发送验证码
export function sendLoginCode(data) {
    return POST('/api/user/login-send-sms', data)
}

//用户-手机登录-提交
export function loginByCode(data) {
    return POST('/api/user/login-by-sms', data)
}

//用户-修改密码
export function updatePassword(data) {
    return POST('/api/user/password', data)
}

//获取奖品详情
export function getDetail(data) {
    return POST('/api/my/item/detail', data)
}

//一键回收
export function recycleAll(data) {
    return POST('/api/my/set-recycle-batch', data)
}

//form
export function postForm(url,data) {
    return POST(url, data)
}