<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import {useRoute} from "vue-router";
import nav_home_normal from "@/assets/img/nav_home_normal.png"
import nav_home_selected from "@/assets/img/nav_home_selected.png"
import nav_recharge_normal from "@/assets/img/nav_recharge_normal.png"
import nav_recharge_selected from "@/assets/img/nav_recharge_selected.png"
import nav_repo_normal from "@/assets/img/nav_repo_normal.png"
import nav_repo_selected from "@/assets/img/nav_repo_selected.png"
import nav_mine_normal from "@/assets/img/nav_mine_normal.png"
import nav_mine_selected from "@/assets/img/nav_mine_selected.png"

const emits = defineEmits(["tabSelected"])
const route = useRoute()
const state = reactive({
  activeIndex: 0
})
const getImageUrl = (url) => {
  const path = new URL(url, import.meta.url)
  return path.href
}

watch(route, (newVal, oldVal) => {
  console.log(newVal.query)
  state.activeIndex = Number(newVal?.query?.index)
})

const icons = ref([
  {
    icon_normal: nav_home_normal,
    icon_selected: nav_home_selected,
    label: "首页",
    path: "/home?index=0"
  },
  {
    icon_normal: nav_recharge_normal,
    icon_selected: nav_recharge_selected,
    label: "充值",
    path: "/recharge?index=1"
  },
  {
    icon_normal: nav_repo_normal,
    icon_selected: nav_repo_selected,
    label: "仓库",
    path: "/repo?index=2"
  },
  {
    icon_normal: nav_mine_normal,
    icon_selected: nav_mine_selected,
    label: "我的",
    path: "/mine?index=3"
  },
])

const onTabClick = (path, index) => {
  state.activeIndex = index
  if (path) {
    emits("tabSelected", path)
  }
}

onMounted(() => {
  console.log("route===", route.query)
  state.activeIndex = Number(route.query.index)
  console.log(state.activeIndex)
  // onTabClick(null,state.activeIndex)
})
</script>

<template>
  <div class="tab-box">
    <div class="tab" v-for="(item,index) in icons" :key="item.label" @click="onTabClick(item.path,index)">
      <img class="tab_normal" :class="state.activeIndex===index?'tab_selected':''"
           :src="state.activeIndex===index?item.icon_selected:item.icon_normal"/>
      <div class="label">{{ item.label }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">


.tab-box {
  display: flex;
  flex-direction: row;
  height: 54px;
  justify-content: space-around;
  width: 100%;
  min-width: 375px !important;
}

.tab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  justify-content: flex-end;
  cursor: pointer;

  .text {
    //margin-top: 4px;
  }
}

.tab_normal {
  width: 24px;
  height: 24px;
}

.tab_selected {
  width: 36px;
  height: 36px;
}

.label{
  color: white;
}

</style>