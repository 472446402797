<script setup>
import {nextTick, onMounted, reactive, ref} from "vue";
import {exchangeCode, getDetail, getList, postForm} from "@/services/api.js";
import Cookies from "js-cookie";
import {showSuccessToast, showToast} from 'vant'
import useClipboard from 'vue-clipboard3';

const domList = ref(null)
const {toClipboard} = useClipboard();
const state = reactive({
  tabList: [
    {
      label: "全部",
      type: 1
    }, {
      label: "兑换中",
      type: 2
    }, {
      label: "已兑换",
      type: 3
    },
  ],
  headerList: [
    {
      label: "奖品",
      type: 1
    }, {
      label: "名称",
      type: 2
    }, {
      label: "日期",
      type: 3
    },
  ],
  list: [],
  tabIndex: 0,
  page: 0,
  itemIndex: 0,
  loading: false,
  finished: false,
  empty: false,
  showPopu: false,
  showDHFS: false,
  showDHM: false,
  status: "",
  action: "",
  id: "",
  data: "",
  currentItem: {}
})

const onTabClick = (index) => {
  state.tabIndex = index
  if (index === 0) {
    state.status = ""
  } else if (index === 1) {
    state.status = "progress"
  } else {
    state.status = "finished"
  }
  state.page = 1
  state.list = []
  onLoad()
}

const getRepoList = () => {
  state.page++
  getList({token: Cookies.get("token"), page: state.page, status: state.status}).then(res => {
    state.loading = false
    if (res.data.pagination.max_page === res.data.pagination.page) {
      state.finished = true
    }
    if (res.data.item_list == null || res.data.item_list.length === 0) {
      state.empty = true
    } else {
      state.list = state.list.concat(res.data.item_list)
      state.empty = false
    }
  })
}

const onLoad = () => {
  state.loading = true
  console.log("加载嗯多")
  getRepoList()
}

const onExchangeClick = (data, index) => {
  state.id = data.id
  state.itemIndex = index
  state.data = data
  state.action = data.next_op?.exchange.action
  state.currentItem = data.next_op?.exchange.form
  console.log(state.currentItem)
  state.showPopu = true
}

const onShowDHMClick = (data, index) => {
  state.data = data
  state.showDHM = true
}
const goDomain = (url) => {
  window.open(url, "_blank")
}

const onRecycleClick = (data, index) => {
  state.id = data.id
  state.itemIndex = index
  state.data = data
  state.action = data.next_op?.recycle.action
  state.currentItem = data.next_op?.recycle.form
  console.log(state.currentItem)
  state.showPopu = true
}

const copy = () => {
  try {
    toClipboard(state.data.code);
    showSuccessToast('兑换码已复制');
  } catch (e) {
    console.error(e);
  }
}

const submitForm = () => {
  let form = new FormData()
  state.currentItem?.map(el => {
    form.append(el.name, el.value)
  })
  form.append("id", state.id)
  form.append("token", Cookies.get("token"))
  postForm("/api" + state.action, form).then(res => {
    state.showPopu = false
    showToast("操作成功")
    getData()
  })
}

const getData = () => {
  getDetail({id: state.id, token: Cookies.get("token")}).then(res => {
    state.list[state.itemIndex] = res.data.item || {}
  })
}

onMounted(() => {
  console.log(domList.value)
  domList.value.addEventListener('scroll', (event) => {
    console.log(21212)
    const {scrollTop, clientHeight, scrollHeight} = event.target
    if (scrollTop + clientHeight > scrollHeight - 100 && !state.loading && !state.finished) {
      onLoad()
    }
  })
  onLoad()
})

</script>

<template>
  <div class="wrapper">
    <div class="top_tab">
      <div class="tab_box">
        <div class="tab" v-for="(item,index) in state.tabList" :class="state.tabIndex===index?'tab_active':''"
             @click="onTabClick(index)">{{ item.label }}
        </div>
      </div>
      <div class="table_header" v-if="!state.empty">
        <div class="tab" v-for="(item,index) in state.headerList">{{ item.label }}
        </div>
      </div>
    </div>
    <div class="list_box" ref="domList">
      <div v-if="!state.empty" class="item_box" v-for="(item,index) in state.list" ref="domList">
        <div class="img_bg">
          <img class="img" :src="item.image_url"/>
        </div>
        <div class="info_box">
          <div class="info">
            <div class="name" @click="false">{{ item.title }}</div>
            <div class="date" @click="false">{{ item.get_time }}</div>
          </div>
          <div class="line"></div>
          <div class="btn_box" v-if="item.next_by==='system'">
            <div class="btn_system" v-if="item?.show_exchange===1" @click="onExchangeClick(item,index)">立即兑换</div>
            <div class="btn_system" v-if="item?.show_code_exchange_tip===1" @click="state.showDHFS=true">
              兑换方式
            </div>
            <div class="btn_system" v-if="item?.show_code_view===1" @click="onShowDHMClick(item,index)">查看兑换码
            </div>
            <div class="btn_system" v-if="item?.show_code_use===1" @click="goDomain(item.code_use_url)">立即使用</div>
            <div class="btn_ing" v-if="item.show_status===1">{{ item.status_text }}</div>
          </div>
          <div class="btn_box" v-else>
            <div class="btn_dh" v-if="item?.show_exchange===1" @click="onExchangeClick(item,index)">立即兑换
            </div>
            <div class="btn_fx" v-if="item.show_recycle===1" @click="onRecycleClick(item,index)">点击返现</div>
            <div class="btn_ing" v-if="item.show_status===1">{{ item.status_text }}</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <img class="icon" src="../../assets/img/list_empty.png"/>
        <div class="title">暂无记录</div>
      </div>
    </div>

    <van-popup v-model:show="state.showPopu">
      <div class="popu">
        <div class="title" v-if="state.data.next_by==='system'">确认兑换奖品？</div>
        <div class="title" v-else>请填写相关信息</div>
        <div class="item" v-if="state.data.next_by==='system'">
          <div class="label">兑换码</div>
          <input class="input" v-model="state.data.code" disabled></input>
          <div class="copy" @click="copy">复制</div>
        </div>
        <div class="second_title" v-if="state.data.next_by==='system'">请截图保存，遗失不补</div>
        <div class="item" v-for="(item,index) in state.currentItem">
          <div class="label">{{ item.title }}</div>
          <input class="input" v-model="item.value" :placeholder="'请填写'+item.title"></input>
        </div>
        <div class="btn" @click="submitForm">确认</div>
      </div>
    </van-popup>
    <van-popup v-model:show="state.showDHM">
      <div class="popu">
        <div class="title" v-if="state.data.next_by==='system'">兑换码</div>
        <div class="item" v-if="state.data.next_by==='system'">
          <div class="label">兑换码</div>
          <input class="input" v-model="state.data.code" disabled></input>
          <div class="copy" @click="copy">复制</div>
        </div>
        <div class="second_title" v-if="state.data.next_by==='system'">请截图保存，遗失不补</div>
        <div class="btn" @click="state.showDHM=false">确认</div>
      </div>
    </van-popup>
    <van-popup v-model:show="state.showDHFS">
      <div class="popu">
        <div class="title">兑换方式</div>
        <div class="second_title_box">
          <div class="second_title">1、点击【立即兑换】复制兑换码</div>
          <div class="second_title">2、点击【立即使用】跳转漫画网页</div>
          <div class="second_title">3、在漫画网页点击下方【我的】-【兑换码兑换】</div>
          <div class="second_title">4、输入兑换码，确认兑换</div>
          <div class="second_title">5、每个兑换码只限兑换一次，请勿重复使用</div>
        </div>
        <div class="notice">
          <div>温馨提示：</div>
          <div>1、请保存您的漫画账号密码防止丢失</div>
          <div>2、兑换前请绑定手机号，若账号丢失无法找回</div>
          <div>3、同一漫画账号可多次兑换奖品</div>
        </div>
        <div class="btn" @click="state.showDHFS=false">确认</div>
      </div>
    </van-popup>
  </div>
</template>

<style lang="scss" scoped>

@media (min-width: 1024px) {
  .wrapper {
    max-width: 490px;

    .top_tab,.list_box {
      max-width: 490px;
    }
  }
}

.wrapper {
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-width: 375px;
  min-height: 100%;
  height: unset;


  .top_tab {
    width: 100%;
    position: fixed;
    padding-top: 20px;
    top: 0;

    .tab_box {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0px 18px 0px 18px;
      justify-content: space-around;

      .tab {
        cursor: pointer;
        width: 100px;
        height: 40px;
        line-height: 40px;
        background-image: url("@/assets/img/tab_normal.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #C8ADFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.2px;
        text-align: center;
      }

      .tab_active {
        background-image: url("@/assets/img/tab_selected.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #FFF;
        text-shadow: 0px 0px 8px #8C8AFF;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.2px;
      }
    }

    .table_header {
      display: flex;
      flex-direction: row;
      margin: 17px 16px 0px 16px;
      justify-content: space-around;
      background-image: url("@/assets/img/repo_tab_header.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 46px;

      .tab {
        width: 100px;
        height: 46px;
        line-height: 46px;
        color: #FFF;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
      }
    }

  }


  .van-popup {
    background: transparent !important;
  }

  .popu {
    width: 320px;
    min-height: 200px;
    border-radius: 12px;
    background: #282643;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-top: 20px;
    }

    .notice {
      color: white;
      font-size: 11px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 90%;
      margin-top: 20px;
    }

    .second_title {
      font-size: 13px;
      color: white;
      margin-top: 10px;
    }

    .second_title_box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 90%;
      margin-top: 20px;

      .second_title {
        font-size: 12px;
        color: white;
        margin-top: 0px;
      }

    }

    .btn {
      width: 80%;
      height: 40px;
      line-height: 40px;
      background-image: url("@/assets/img/recharge_btn_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-top: 20px;
      margin-bottom: 20px;
      color: white;
      font-size: 16px;
    }

    .btn_box {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .btn1 {
        width: 45%;
        height: 40px;
        line-height: 40px;
        background-image: url("@/assets/img/recharge_btn_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 20px;
        margin-bottom: 20px;
        color: white;
        border-radius: 12px;
        font-size: 16px;
      }

    }

    .item {
      width: 80%;
      height: 36px;
      background-image: url("@/assets/img/repo_tab_header.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;


      .label {
        font-size: 13px;
        font-weight: bold;
        color: white;
        min-width: 60px;
      }

      .copy {
        font-size: 13px;
        font-weight: bold;
        color: white;
        min-width: 40px;
        margin-right: 20px;
        display: inline-block;
      }

      .input {
        flex: 1;
        height: 30px;
        border: none;
        background: transparent;
        text-align: start;
        font-size: 13px;
        color: white;
      }
    }
  }


  .list_box {
    //display: flex;
    //flex-direction: column;
    width: 100%;
    margin-top: 130px;
    height: calc(100% - 200px);
    overflow-y: auto;
    position: fixed;

    .empty {
      width: 100%;
      position: absolute;
      top: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        width: 276px;
        object-fit: cover;
      }

      .title {
        color: #FFF;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
      }
    }

    .item_box {
      display: flex;
      flex-direction: row;
      margin: 6px 16px 0px 16px;
      justify-content: space-around;
      background-image: url("@/assets/img/repo_item_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      align-items: center;
      min-height: 126px;
      height: 126px;


      .img_bg {
        background-image: url("@/assets/img/repo_img_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 94px;
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        .img {
          width: 52px;
          height: 52px;
          border-radius: 6px;
        }
      }


      .info_box {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 94px;
        margin-left: 20px;
        padding-right: 17px;


        .line {
          height: 1px !important;
          opacity: 0.1;
          margin-top: 5px;
          background: #F4F7FF;
        }

        .btn_box {
          width: 100%;
          height: 36px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 15px;
          justify-content: space-between;


          .btn_system {
            height: 36px;
            line-height: 36px;
            color: var(--Others-White, #FFF);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.2px;
            background-image: url("@/assets/img/repo_btn1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            cursor: pointer;
            width: 45%;
          }

          .btn_dh, .btn_fx {
            height: 36px;
            flex: 1;
            line-height: 36px;
            color: var(--Others-White, #FFF);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.2px;
            background-image: url("@/assets/img/repo_btn1.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            cursor: pointer;
          }

          .btn_dh {
            margin-right: 25px;
          }

          .btn_ing {
            cursor: pointer;
            flex: 1;
            line-height: 36px;
            height: 36px;
            color: var(--Others-White, #FFF);
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.2px;
            background-image: url("@/assets/img/repo_btn2.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .info {
          height: 30px;
          width: 100%;
          display: flex;
          flex-direction: row;

          .name {
            width: 50%;
            line-height: 30px;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            text-align: start;
            text-decoration: none;
            font-style: normal;
          }

          .date {
            width: 50%;
            color: white;
            text-align: end;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-decoration: none;
            font-style: normal;
          }
        }
      }
    }

  }


}
</style>