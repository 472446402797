<script setup>
import {nextTick, onMounted, reactive, ref} from "vue";
import {getDetail, getList, getRecycleList, postForm, recycleAll} from "@/services/api.js";
import Cookies from "js-cookie";
import {showToast} from 'vant'
import {useRouter} from "vue-router";

const domList = ref(null)
const router = useRouter()
const state = reactive({
  tabList: [
    {
      label: "一键回收",
      type: 1
    }
  ],
  headerList: [
    {
      label: "奖品",
      type: 1
    }, {
      label: "名称",
      type: 2
    }, {
      label: "日期",
      type: 3
    },
  ],
  list: [],
  recycle_form: [],
  tabIndex: 0,
  page: 0,
  itemIndex: 0,
  loading: false,
  finished: false,
  empty: false,
  showPopu: false,
  status: "",
  action: "",
  id: "",
  total_price_yuan: "",
  currentItem: {}
})

const onTabClick = (index) => {
  router.back(-1)
}

const getRepoList = () => {
  getRecycleList({token: Cookies.get("token")}).then(res => {
    state.loading = false
    if (res.data.item_list == null || res.data.item_list.length === 0) {
      state.empty = true
      state.list = []
    } else {
      state.list = res.data.item_list
      state.total_price_yuan = res.data.total_price_yuan
      state.empty = false
      state.recycle_form = res.data.recycle_form
    }
  })
}

const onLoad = () => {
  state.loading = true
  console.log("加载嗯多")
  getRepoList()
}

const onRecycleClick = (data, index) => {
  state.showPopu = true
}

const submitRecycleForm = () => {
  let arr = []
  state.list.map(el => {
    arr.push(el?.id)
  })
  console.log(state.recycle_form)
  let form = {}
  state.recycle_form?.map(el => {
    // form.append(el.name, el.value)
    form[el.name]=el.value
  })
  console.log("form===",form)
  recycleAll({
    recycle_info: form,
    "token": Cookies.get("token"),
    winner_ids: arr,
    total_price_yuan: state.total_price_yuan
  }).then(res => {
    state.showPopu = false
    state.list = []
    showToast(res.message)
    onLoad()
  })
}

const getData = () => {
  getDetail({id: state.id, token: Cookies.get("token")}).then(res => {
    state.list[state.itemIndex] = res.data.item || {}
  })
}

onMounted(() => {
  console.log(domList.value)
  onLoad()
})

</script>

<template>
  <div class="wrapper">
    <div class="tab_box">
      <div class="tab" v-for="(item,index) in state.tabList" :class="state.tabIndex===index?'tab_active':''"
           @click="onTabClick(index)">
        <img class="icon" src="@/assets/img/arrow_right.png"/>
        <span> {{ item.label }}</span>
      </div>
    </div>
    <div class="table_header" v-if="state.list.length>0">
      <div class="tab" v-for="(item,index) in state.headerList">{{ item.label }}
      </div>
    </div>
    <div class="list_box" ref="domList">
      <div v-if="!state.empty" class="item_box" v-for="(item,index) in state.list" ref="domList">
        <div class="img_bg">
          <img class="img" :src="item.image_url"/>
        </div>
        <div class="info_box">
          <div class="info">
            <div class="name">{{ item.title }}</div>
            <div class="date">{{ item.recycle_price_yuan }}元</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <img class="icon" src="../../assets/img/list_empty.png"/>
        <div class="title">暂无记录</div>
      </div>
    </div>
    <div class="btn" @click="onRecycleClick" v-if="state.list.length>0">一键回收</div>

    <van-popup v-model:show="state.showPopu">
      <div class="popu">
        <div class="title">确认回收所有奖品?</div>
        <div class="second_title">物品回收总价为<span class="money">{{ state.total_price_yuan }}</span>元</div>
        <div class="item" v-for="item in state.recycle_form">
          <div class="label">{{ item.title }}</div>
          <input class="input" v-model="item.value" :placeholder="'请填写'+item.title"></input>
        </div>
        <div class="btn_box">
          <!--          <div class="btn_dh">人工客服</div>-->
          <div class="btn_fx" @click="submitRecycleForm">确认回收</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 40px;
  padding-bottom: 90px;
  min-width: 375px;


  .van-popup {
    background: transparent !important;
  }

  .popu {
    width: 320px;
    min-height: 260px;
    border-radius: 12px;
    background: #282643;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      margin-top: 20px;
    }

    .item {
      width: 80%;
      height: 36px;
      margin-left: 10%;
      background-image: url("@/assets/img/repo_tab_header.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;


      .label {
        font-size: 13px;
        font-weight: bold;
        color: white;
        min-width: 80px;
      }

      .copy {
        font-size: 13px;
        font-weight: bold;
        color: white;
        min-width: 40px;
        margin-right: 20px;
        display: inline-block;
      }

      .input {
        flex: 1;
        height: 30px;
        border: none;
        background: transparent;
        text-align: start;
        font-size: 13px;
        color: white;
      }
    }

    .second_title {
      font-size: 14px;
      color: white;
      margin-top: 20px;

      .money {
        font-size: 18px;
        font-weight: bold;
        margin: 0px 10px 0px 10px;
      }
    }

    .btn_box {
      width: 80%;
      height: 36px;
      display: flex;
      margin-left: 10%;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      justify-content: center;

      .btn_dh, .btn_fx {
        height: 36px;
        width: 40%;
        line-height: 36px;
        color: var(--Others-White, #FFF);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.2px;
        background-image: url("@/assets/img/repo_btn1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .btn_dh {
        margin-right: 25px;
      }
    }

    .item {
      width: 80%;
      height: 36px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
    }
  }


  .tab_box {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 18px 0px 18px;
    justify-content: flex-start;

    .tab {
      width: 100px;
      height: 40px;
      line-height: 40px;
      background-image: url("@/assets/img/tab_normal.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #C8ADFF;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 15px;
        height: 15px;
        object-fit: fill;
        transform: rotate(180deg);
      }
    }

    .tab_active {
      background-image: url("@/assets/img/tab_selected.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #FFF;
      text-shadow: 0px 0px 8px #8C8AFF;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0.2px;
    }
  }

  .table_header {
    display: flex;
    flex-direction: row;
    margin: 17px 16px 0px 16px;
    justify-content: space-around;
    background-image: url("@/assets/img/repo_tab_header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 46px;

    .tab {
      width: 100px;
      height: 46px;
      line-height: 46px;
      color: #FFF;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .btn {
    width: 80%;
    height: 44px;
    line-height: 44px;
    background-image: url("@/assets/img/recharge_btn_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 24px;
    color: white;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
    margin-left: 10%;
    text-align: center;
  }

  .list_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    margin-top: 6px;

    .empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50%;

      .icon {
        width: 276px;
        object-fit: cover;
      }

      .title {
        color: #FFF;
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 20px;
      }
    }

    .item_box {
      display: flex;
      flex-direction: row;
      margin: 6px 16px 0px 16px;
      justify-content: space-around;
      background-image: url("@/assets/img/repo_item_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      align-items: center;
      min-height: 126px;


      .img_bg {
        background-image: url("@/assets/img/repo_img_bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 94px;
        height: 94px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;

        .img {
          width: 52px;
          height: 52px;
          border-radius: 6px;
        }
      }


      .info_box {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 94px;
        margin-left: 20px;
        padding-right: 10px;
        align-items: center;
        justify-content: center;

        .info {
          height: 30px;
          width: 100%;
          display: flex;
          flex-direction: row;

          .name {
            width: 50%;
            line-height: 30px;
            padding-left: 13px;
            color: #FFF;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
          }

          .date {
            width: 50%;
            color: white;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
          }


        }
      }
    }

  }


}
</style>