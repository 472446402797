<script setup>
import {useStore} from "vuex";
import {computed, onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import {updatePassword} from "@/services/api.js";
import {showToast} from "vant";
import Cookies from "js-cookie";

const store = useStore()
const router = useRouter()
const userInfo = computed(() => store.state.userInfo)
const toOther = (path) => {
  router.push(path)
}
const state = reactive({
  telephone: "",
  isSubmit: false,
  code: "",
  old_password: "",
  new_password: "",
  renew_password: "",
  codeMsg: "发送验证码",
  time: 0
})

const submit = () => {
  if (!state.old_password) {
    showToast("请输入原密码")
    return
  }
  if (!state.new_password) {
    showToast("请输入新密码")
    return
  }
  if (!state.renew_password) {
    showToast("请再次输入新密码")
    return
  }
  updatePassword({
    token: Cookies.get("token"),
    old_password: state.old_password,
    new_password: state.new_password,
    renew_password: state.renew_password,
  }).then(res => {
    if (res.status === 1) {
      showToast("密码修改成功")
      localStorage.setItem("token", res.data.token)
      Cookies.set("token", res.data.token)
      store.dispatch("getUser")
      setTimeout(() => {
        router.back()
      }, 1500)
    }
  })
}
onMounted(() => {
})
</script>

<template>
  <div class="wrapper">
    <div class="header">
      <img class="back" src="@/assets/img/back.png" @click="router.back()"/>
      <div class="title">修改密码</div>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_user.png"/>
      <input class="input" type="text" v-model="userInfo.username" placeholder="请输入帐号" maxlength="10"
             :disabled="true"/>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_phone.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.old_password ? state.old_password : '请输入原密码'"
             v-model="state.old_password"/>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_phone.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.new_password ? state.new_password : '请输入新密码'"
             v-model="state.new_password"/>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_phone.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.renew_password ? state.renew_password : '请再次输入新密码'"
             v-model="state.renew_password"/>
    </div>
    <div class="submit" @click="submit">
      <div class="n-btn ">确认</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  min-width: 375px;
  align-items: center;

  .header {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    .back {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      flex: 1;
    }
  }


  .submit {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/recharge_btn_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 30px;
    cursor: pointer;
  }


  .input-item {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/repo_tab_header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    justify-content: center;
    color: white;


    .send-txt {
      min-width: 80px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      font-size: 13px;
      color: white;
      cursor: pointer;
    }


    .my-icon {
      width: 15px;
      height: 15px;
      margin-left: 12px;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      color: white;
      width: 100px;
    }

    .input {
      flex: 1;
      height: 30px;
      border: none;
      background: transparent;
      text-align: start;
      color: white;
      font-size: 14px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

}
</style>