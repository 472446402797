import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
import Index from '../views/index/Index.vue'
import HomeVue from '@/views/home/Index.vue'
import RechargeVue from '@/views/recharge/Index.vue'
import RepoVue from '@/views/repo/Index.vue'
import RepoLogVue from '@/views/repo/Log.vue'
import RechargeLogVue from '@/views/recharge/Log.vue'
import MineVue from '@/views/mine/Index.vue'
import RecycleVue from '@/views/recycle/Index.vue'
import BindPhoneVue from '@/views/bind/Index.vue'
import UpdatePasswordVue from '@/views/update/Index.vue'
import LoginVue from '@/views/login/Index.vue'

const routes = [{
    path: '/', name: 'Index', component: Index, redirect: "/home?index=0", children: [{
        path: '/home', name: 'HomeVue', component: HomeVue, meta: {keepalive: true}
    }, {
        path: '/recharge', name: 'RechargeVue', component: RechargeVue, meta: {keepalive: true}
    }, {
        path: '/repo', name: 'RepoVue', component: RepoVue, meta: {keepalive: false}
    }, {
        path: '/mine', name: 'MineVue', component: MineVue, meta: {keepalive: true}
    },],
}, {
    path: '/repoLog', name: 'RepoLogVue', component: RepoLogVue, meta: {keepalive: false}
}, {
    path: '/rechargeLog', name: 'RechargeLogVue', component: RechargeLogVue, meta: {keepalive: false}
}, {
    path: '/recycle', name: 'RecycleVue', component: RecycleVue, meta: {keepalive: false}
}, {
    path: '/bind', name: 'BindPhoneVue', component: BindPhoneVue, meta: {keepalive: false}
}, {
    path: '/update', name: 'UpdatePasswordVue', component: UpdatePasswordVue, meta: {keepalive: false}
}, {
    path: '/login', name: 'LoginVue', component: LoginVue, meta: {keepalive: false}
}]

const router = createRouter({
    history: createWebHistory(), routes
})

router.afterEach((to, from) => {
    window.scrollTo(0, 0)    //滚动条至于浏览器顶部
})

export default router
