<script setup>
import NavVue from '@/components/nav/Index.vue'
import {ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const store = useStore()
const route = useRoute()
const activeIndex = ref(0)
const onTabSelected = (path) => {
  router.push(path)
}
watch(activeIndex, (newValue, oldValue) => {

})


</script>

<template>
  <div class="wrapper">
    <div class="main">
      <router-view v-show="!store.state.showLoading" v-slot="{ Component }">
        <keep-alive v-if="route.meta.keepalive">
          <component :is="Component":key="route.path"/>
        </keep-alive>
        <component :is="Component" v-else/>
      </router-view>
    </div>
    <div class="nav">
      <NavVue @tabSelected="onTabSelected"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  height: calc(100% - 78px);
}

.nav {
  height: 78px;
  background-image: url("@/assets/img/nav_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #0E0E19;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 490px;
  z-index: 1000;
}
</style>