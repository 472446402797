<script setup>
import {getHome, lottery} from "@/services/api.js"
import {computed, onMounted, reactive, ref, watch} from "vue";
import {Vue3SeamlessScroll} from 'vue3-seamless-scroll'
import Cookies from "js-cookie";
import {showSuccessToast, showToast} from "vant";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const store = useStore()
const route = useRoute()
const state = reactive({
  list: [],
  list2: [],
  winnerList: [],
  scroll: false,
  showPopu: false,
  showRecharge: false,
  isLottery: false,
  dialogShowed: false,
  config: {},
  userInfo: {},
  activeIndex: -1
})

state.userInfo = computed(() => {
  return store.state.userInfo
})

state.config = computed(() => {
  return store.state.config
})

watch(store.state, (newVal, oldVal) => {
  // console.log("1111=", newVal.userInfo)
  // console.log("dialogShowed=", state.dialogShowed)
  // if (!state.dialogShowed) {
  //   state.showPopu = (newVal.userInfo.telephone === "")
  //   console.log("showPopu=", state.showPopu)
  //   state.dialogShowed = true
  // }
})

onMounted(() => {
  console.log("开始")

  // store.dispatch("getUser")
  setTimeout(() => {
    if (!state.dialogShowed) {
      state.showPopu = (store.state.userInfo.telephone === "")
      console.log("showPopu=", state.showPopu)
      state.dialogShowed = true
    }
  }, 1000)

  getHome({}).then(res => {
    state.list = res.data.item_list || []
    state.winnerList = res.data.winner_list.concat(res.data.winner_list)
    if (res.data.winner_list && res.data.winner_list.length > 0) {
      state.scroll = true
    }
  })
})

const lotteryRequest = (count) => {
  state.isLottery = true
  lottery({token: Cookies.get("token"), lottery_count: count}).then(res => {
    if (res.status === 1) {
      state.list2 = res.data.item_list || []
      state.activeIndex = -1
      console.log(state.isLottery)
      store.dispatch("getUser")
      showSuccessToast("抽奖成功，请在仓库查看您的奖品")
    } else {
      state.showRecharge = true
    }
  })
}

const randomIndex = () => {
  return Math.floor(Math.random() * 10);
}

const lottieTask = (count) => {
  if (count === 1 && state.userInfo?.lottery_amount < 1) {
    state.showRecharge = true
    return
  }
  if (count === 5 && state.userInfo?.lottery_amount < 5) {
    state.showRecharge = true
    return
  }
  state.isLottery = false
  const interval = setInterval(() => {
    state.activeIndex = randomIndex()
  }, 100)
  setTimeout(() => {
    console.log("结束任务")
    clearInterval(interval)
    lotteryRequest(count)
  }, 3000)
}


const toBindPhone = () => {
  state.showPopu = false
  router.push("/bind")
}

const toRecharge = () => {
  state.showRecharge = false
  router.push("/recharge?index=1")
}

</script>

<template>
  <div class="wrapper">
    <!--    <img class="home_bg" src="@/assets/img/home_bg.png"/>-->
    <img class="logo" src="../../assets/img/home_logo.png"/>
    <div class="tab_box">
      <div class="count">剩余抽奖次数： <span class="number">{{ state.userInfo?.lottery_amount }}</span>&nbsp; 次</div>
      <div class="item_box">
        <div v-if="state.isLottery" class="tab" v-for="(item,index) in state.list2" :key="index"
             :class="item.get===1?'tab_active':''">
          <img class="icon" :src="item.image_url"/>
          <div class="text">{{ item.title }}</div>
        </div>
        <div v-else class="tab" v-for="(item,index2) in state.list" :key="index2"
             :class="state.activeIndex===index2?'tab_active':''">
          <img class="icon" :src="item.image_url"/>
          <div class="text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="cj_box">
      <img class="img_btn" src="../../assets/img/click1.png" @click="lottieTask(1)"/>
      <img class="img_btn" src="../../assets/img/click5.png" @click="lottieTask(5)"/>
    </div>
    <div class="box1">
      <div class="title">大奖展示</div>
      <vue3-seamless-scroll :list="state.winnerList" class="list" :step="0.5" v-model="state.scroll">
        <div class="item" v-for="(item,index) in state.winnerList" :key="index">
          <img class="icon" :src="item.item.image_url"/>
          <div class="name">恭喜{{ item.name }}抽中{{ item.item.title }}</div>
          <!--          <img class="user_icon" src="../../assets/img/user.png"/>-->
          <!--          <div class="phone">{{ item.telephone }}</div>-->
        </div>
      </vue3-seamless-scroll>
      <div class="bottom-shadow"></div>
    </div>
    <div class="box2">
      <div class="title">免责声明</div>
      <div class="content" v-html="state.config.disclaimer"></div>
      <div class="title">注意事项</div>
      <div class="content" v-html="state.config.return_rule"></div>
    </div>
    <!--    <div style="width: 100%;height: 140px;"></div>-->
    <van-popup v-model:show="state.showPopu">
      <div class="popu">
        <div class="title">重要提示</div>
        <div class="second_title">抽奖前请绑定手机号，若账号丢失则无法兑换奖品!</div>
        <div class="btn_box2">
          <div class="btn_dh" @click="state.showPopu = false">下次再绑</div>
          <div class="btn_fx" @click="toBindPhone">前往绑定</div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="state.showRecharge">
      <div class="popu">
        <div class="title">重要提示</div>
        <div class="second_title">抽奖次数不足，请充值!</div>
        <div class="btn_box2">
          <div class="btn_dh" @click="state.showRecharge=false">取消</div>
          <div class="btn_fx" @click="toRecharge">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-width: 375px;
  background-image: url("@/assets/img/home_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  height: unset;

  .home_bg {
    width: 100%;
    height: 180vh !important;
    object-fit: fill;
    position: absolute;
    z-index: -1;
  }


  .van-popup {
    background: transparent !important;
  }

  .popu {
    width: 320px;
    min-height: 180px;
    border-radius: 12px;
    background: #282643;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      width: 80%;
      margin-bottom: 10px;
    }

    .second_title {
      font-size: 14px;
      color: white;
      width: 80%;
      margin-bottom: 10px;
    }

    .btn_box {
      width: 40%;
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;

      .btn_dh, .btn_fx {
        height: 36px;
        flex: 1;
        line-height: 36px;
        color: var(--Others-White, #FFF);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.2px;
        background-image: url("@/assets/img/repo_btn1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .btn_dh {
        margin-right: 25px;
      }
    }

    .btn_box2 {
      width: 80%;
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 15px;
      justify-content: space-between;

      .btn_dh, .btn_fx {
        height: 36px;
        flex: 1;
        line-height: 36px;
        color: var(--Others-White, #FFF);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.2px;
        background-image: url("@/assets/img/repo_btn1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .btn_dh {
        margin-right: 25px;
      }
    }

    .item {
      width: 80%;
      height: 36px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 10px;
    }
  }


  .logo {
    object-fit: cover;
    width: 333px;
    height: 187px;
    margin-top: 16px;
  }

  .tab_box {
    background-image: url("@/assets/img/box1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 324px;
    height: 377px;
    display: flex;
    flex-direction: column;
    align-items: center;


    .item_box {
      background-image: url("@/assets/img/home_tab_box.png");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 274px;
      height: 274px;
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      flex-shrink: 0;
      padding: 6px;


      .tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background-image: url("@/assets/img/tab_bg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        flex-shrink: 0;
        //background: red;

        .icon {
          width: 40px;
          height: 40px;
          border-radius: 6px;
        }

        .text {
          color: white;
          font-size: 9px;
          margin-top: 6px;
        }
      }

      .tab_active {
        background-image: url("@/assets/img/home_tab_box_selected.png");
        background-size: 100%;
        background-repeat: no-repeat;
        width: 80px;
        height: 80px;
      }

    }

    .count {
      background-image: url("@/assets/img/home_cj_count.png");
      background-size: 100%;
      background-repeat: no-repeat;
      width: 222px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
      font-size: 13px;
      font-weight: bold;
      color: white;

      .number {
        color: white;
      }
    }
  }

  .cj_box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 58px;

    .img_btn {
      width: 160px;
      height: 58px;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .box1 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("@/assets/img/box1.png");
    margin-top: 20px;
    width: 344px;
    height: 300px;
    overflow: hidden;
    position: relative;

    .bottom-shadow {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      z-index: 100;
      background: linear-gradient(0deg, var(--global_bgnew_color) 0%, rgba(236, 241, 246, 0) 100%);
    }


    .title {
      font-size: 13px;
      color: white;
      margin-top: 24px;
    }

    .list {
      height: 240px;
      //display: flex;
      //justify-content: center;
      overflow: hidden;


      .item {
        width: 260px;
        height: 26px;
        margin-bottom: 15px;
        background-image: url("@/assets/img/home_cj_count.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 42px;

        .icon {
          width: 17px;
          height: 17px;
          margin-left: 6px;
        }

        .name {
          flex: 1;
          color: #FFFFFF;
          font-size: 11px;
          font-weight: bold;
          text-align: start;
          margin-left: 8px;
          white-space: nowrap; /* 不换行 */
          overflow: hidden; /* 超出部分隐藏 */
          text-overflow: ellipsis; /* 超出部分用省略号表示 */
        }

        .user_icon {
          width: 10px;
          height: 10px;
        }

        .phone {
          color: #FFFFFF;
          opacity: 0.6;
          font-size: 10px;
          margin-right: 14px;
        }
      }
    }
  }

  .box2 {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("@/assets/img/box1.png");
    margin-top: 20px;
    width: 344px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;


    .title {
      font-size: 13px;
      color: white;
      margin-top: 24px;
    }

    .content {
      width: 86%;
      text-align: start;
      font-size: 12px;
      color: white;
      margin-top: 10px;
    }
  }

}
</style>