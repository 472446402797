<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {onMounted} from "vue";
import Cookies from "js-cookie";

const route = useRoute()
const store = useStore()


onMounted(() => {
  window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  }

  store.dispatch("getUser", route.query.t || null)
  // store.dispatch("loginByUserName", {username: "pGAPDhhM", password: "12345678"})
  store.dispatch("getConfig")
})
</script>
<template>
  <div id="app">
    <router-view  v-slot="{ Component }">
      <keep-alive v-if="route.meta.keepAlive">
        <component :is="Component"  :key="route.path"/>
      </keep-alive>
      <component :is="Component" v-else/>
    </router-view>
  </div>
</template>

<style lang="scss">
</style>
