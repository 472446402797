<script setup>
import {useStore} from "vuex";
import {computed, onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import {bindPhone, sendBindCode} from "@/services/api.js";
import {showToast} from "vant";
import Cookies from "js-cookie";

const store = useStore()
const router = useRouter()
const userInfo = computed(() => store.state.userInfo)
const toOther = (path) => {
  router.push(path)
}
const state = reactive({
  telephone: "",
  isSubmit: false,
  code: "",
  codeMsg: "发送验证码",
  time: 0
})
const sendCode = () => {
  console.log("231232312")
  if (!state.telephone) {
    showToast("请填写手机号码")
    return
  }
  if (state.time > 1) {
    return
  }
  state.time = 60;
  sendBindCode({
    token: Cookies.get("token"),
    telephone: state.telephone
  }).then(res => {
    if (res.status === 1) {
      let timer = setInterval(() => {
        state.time--;
        if (state.time <= 0) {
          state.time = 0;
          clearInterval(timer);
        }
      }, 1000)
    } else {
      state.time = 0;
    }
  }).catch(res => {
    state.time = 0;
    showToast(res.message)
  });
}
const submit = () => {
  bindPhone({
    token: Cookies.get("token"),
    telephone: state.telephone,
    code: state.code
  }).then(res => {
    if (res.status === 1) {
      store.dispatch("getUser")
      setTimeout(() => {
        router.back()
      }, 1500)
    }
  })
}
onMounted(() => {
})
</script>

<template>
  <div class="wrapper">
    <div class="header">
      <img class="back" src="@/assets/img/back.png" @click="router.back()"/>
      <div class="title">绑定手机号</div>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_user.png"/>
      <input class="input" type="text" v-model="userInfo.username" placeholder="请输入帐号" maxlength="10"
             :disabled="true"/>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_phone.png"/>
      <input class="input" type="text"
             maxlength="11"
             :placeholder="state.telephone ? state.telephone : '请输入手机号'"
             v-model="state.telephone"/>
    </div>
    <div class="input-item">
      <img class="my-icon" src="@/assets/img/icon_code.png"/>
      <input class="input" type="text" placeholder="请输入验证码" maxlength="6" v-model="state.code"/>
      <div :type="2" class="send-txt" @click="sendCode()">
        {{ state.time === 0 ? state.codeMsg : state.time + ' s' }}
      </div>
    </div>
    <div class="submit">
      <div class="tips-btn" v-if="userInfo.telephone">
        您已绑定手机
      </div>
      <div class="n-btn " v-else @click="submit">确认绑定
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  width: 100%;
  background-image: url("@/assets/img/other_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 12px;
  min-width: 375px;
  align-items: center;

  .header {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    .back {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: white;
      flex: 1;
    }
  }


  .submit {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/recharge_btn_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 30px;
    cursor: pointer;

    .n-btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  }


  .input-item {
    width: 80%;
    height: 40px;
    background-image: url("@/assets/img/repo_tab_header.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    //justify-content: center;
    color: white;



    .my-icon {
      width: 15px;
      height: 15px;
      margin-left: 12px;
    }

    .label {
      font-size: 14px;
      font-weight: bold;
      color: white;
      width: 100px;
    }

    .input {
      height: 30px;
      border: none;
      background: transparent;
      text-align: start;
      color: white;
      font-size: 14px;
      margin-left: 12px;
      //max-width: 180px;
      flex: 1;
      min-width: 100px;
    }


    .send-txt {
      height: 26px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      margin-right: 12px;
      color: white;
      cursor: pointer;
    }

  }

}
</style>